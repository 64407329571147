import React, { forwardRef } from "react";
import "./DatePicker.scss";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DatePicker = forwardRef(
  ({ id, label, customClassName = "", ...rest }, ref) => {
    return (
      <div className={`datePicker ${customClassName}`}>
        {label && (
          <label
            htmlFor={id}
            onClick={() => document.querySelector(`#${id}`)?.focus()}
          >
            {label}
          </label>
        )}

        <ReactDatePicker ref={ref} id={id} {...rest} />
      </div>
    );
  }
);

export default DatePicker;
