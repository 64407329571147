import React, { useCallback, useEffect, useRef, useState } from "react";
import "./Navbar.scss";
import SearchIcon from "../../assets/magnifying-glass.svg";
import { Link } from "react-router-dom";
import { getSearchDrugResults } from "../../apis";
import { debounce } from "../../utils";
import { getFirebaseBackend } from "../../supabase";
import { toast } from "react-toastify";
import useClickOutside from "../../utils/useClickOutside";

const SearchMenu = ({ handleDrugClick }) => {
  const optionsRef = useRef(null);

  const [showOptions, setShowOptions] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [data, setData] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [dosageValue, setDosageValue] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  const handleNameChange = useCallback(
    debounce((inputVal) => handleSearch(inputVal), 1000),
    []
  );

  const handleSearch = async (value) => {
    setDataLoading(true);
    try {
      const res = await getSearchDrugResults(value, 1);
      const drugs = res?.data?.results?.[0]?.patient?.drug;
      if (drugs?.length > 0) {
        const tempData = [];
        drugs.forEach((d, i) => {
          if (d?.openfda) {
            const {
              medicinalproduct,
              drugstructuredosagenumb,
              drugdosageform,
            } = d;
            const drug = {
              id: `${medicinalproduct}${i}`,
              drug: `${medicinalproduct ? medicinalproduct : " "}${
                drugstructuredosagenumb
                  ? `\n${drugstructuredosagenumb}MG`
                  : " "
              } ${drugdosageform ? `\n${drugdosageform}` : ""}`,

              drugFormShow: `${medicinalproduct ? medicinalproduct : " "}${
                drugstructuredosagenumb ? ` ${drugstructuredosagenumb}MG` : " "
              } ${drugdosageform ? `${drugdosageform}` : ""} ${
                d?.openfda?.generic_name?.[0]
                  ? `\nGeneric name: ${d?.openfda?.generic_name?.[0]}`
                  : ""
              }`,

              generic_name: d?.openfda?.generic_name?.[0],
              dosage: drugstructuredosagenumb
                ? `${drugstructuredosagenumb} MG`
                    ?.match(/[a-zA-Z0-9]+/g)
                    ?.join("")
                    ?.toLowerCase()
                : "",
            };
            tempData.push(drug);
          }
        });

        setData(tempData);
        setFilteredData(tempData);
        setDataLoading(false);
      }
    } catch (err) {
      setDataLoading(false);
      console.log("err", err);
    }
  };

  const handleDosageChange = debounce(
    (inputVal) => handleDosageFilter(inputVal),
    500
  );

  const handleDosageFilter = (inputVal) => {
    const tempValue =
      inputVal
        .match(/[a-zA-Z0-9]+/g)
        ?.join("")
        ?.toLowerCase() || "";
    if (tempValue.length > 0) {
      const tempData = data?.filter((d) => d.dosage?.includes(tempValue));
      setFilteredData([...tempData]);
    } else {
      setFilteredData(data);
    }
    setDataLoading(false);
  };

  useClickOutside(optionsRef, () => {
    if (showOptions) {
      setShowOptions(false);
    }
  });

  return (
    <div className="navbar_inputContainer" ref={optionsRef}>
      <img src={SearchIcon} alt="search" />
      <input
        type="text"
        value={searchValue}
        onChange={(e) => {
          setSearchValue(e.target.value);
          setData([]);
          setFilteredData([]);
          setDataLoading(true);
          setDosageValue("");
          handleNameChange(e.target.value);
        }}
        placeholder="Search drug names here"
        onFocus={() => setShowOptions(true)}
      />
      {showOptions && (
        <div className="navbar_inputOptions">
          {data?.length > 0 && (
            <div className="navbar_inputOptionsInput">
              <label htmlFor="dosageFilter">Filter by dosage:</label>
              <input
                type="text"
                id="dosageFilter"
                value={dosageValue}
                placeholder="Enter Dosage e.g 300 mg"
                onChange={(e) => {
                  setDosageValue(e.target.value);
                  setFilteredData([]);
                  setDataLoading(true);
                  handleDosageChange(e.target.value);
                }}
              />
            </div>
          )}
          {filteredData?.length > 0 ? (
            filteredData?.map((d) => (
              <div
                onClick={() => {
                  handleDrugClick(d?.drugFormShow);
                  setDosageValue("");
                  setFilteredData(data);
                  setShowOptions(false);
                }}
                key={d.id}
                className="navbar_inputOption"
              >
                <h3>{d?.drug}</h3>
                <p>Generic Name: {d?.generic_name} </p>
              </div>
            ))
          ) : (
            <h4>
              {dataLoading && searchValue?.length > 0
                ? "Loading..."
                : searchValue?.length > 0 && filteredData?.length === 0
                ? "No Data Found."
                : "Enter value to search."}{" "}
            </h4>
          )}
        </div>
      )}
    </div>
  );
};

const Navbar = ({ login = false, handleDrugClick = () => {} }) => {
  const [token, setToken] = useState(
    JSON.parse(sessionStorage.getItem("session"))?.access_token
  );

  useEffect(() => {
    setTimeout(
      () =>
        setToken(JSON.parse(sessionStorage.getItem("session"))?.access_token),
      500
    );
  }, []);

  const handleLogout = async () => {
    try {
      sessionStorage.removeItem("authUser");
      sessionStorage.removeItem("session");
      setToken(JSON.parse(sessionStorage.getItem("session"))?.access_token);
      let fireBaseBackend = getFirebaseBackend();
      await fireBaseBackend.logout();
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <nav className="navbar">
      <div className="navbar_header">
        <Link to="/">
          <div className="navbar_title">EMARAPP</div>
        </Link>

        {/* {!login && (
          <div className="navbar_searchContainerDesktop">
            <SearchMenu handleDrugClick={handleDrugClick} />
          </div>
        )} */}
        <div className="navbar_btns">
          <a
            href={`${process.env.REACT_APP_BASE_URL}`}
            rel="noreferrer"
            target="_blank"
          >
            <button className="navbar_greenBtn">New MAR</button>
          </a>
          {token ? (
            <button onClick={handleLogout} className="navbar_purpleBtn">
              Logout
            </button>
          ) : (
            <Link to="/login">
              <button className="navbar_purpleBtn">Login</button>
            </Link>
          )}
        </div>
      </div>
      {/* {!login && (
        <div className="navbar_searchContainerMobile">
          <SearchMenu handleDrugClick={handleDrugClick} />
        </div>
      )} */}
    </nav>
  );
};

export default Navbar;
