import React from "react";
import "./scss/index.scss";
import AppRoutes from "./routes";
import { initFirebaseBackend } from "./supabase";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

initFirebaseBackend();

function App() {
  return (
    <>
      <AppRoutes />
      <ToastContainer />
    </>
  );
}

export default App;
