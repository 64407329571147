import React, { useRef, useState } from "react";
import "./ChangePassword.scss";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import FormikInput from "../../components/FormikInput";
import Eye from "../../assets/eye.svg";
import EyeSlash from "../../assets/eye-slash.svg";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "../../components/Navbar";
import { getFirebaseBackend } from "../../supabase";
import { toast } from "react-toastify";

const initialValues = {
  password: "",
  confirmPassword: "",
};

const ContactTeamSchema = Yup.object().shape({
  password: Yup.string()
    .required("Password is required.")
    .min(8, "Password should be 8 characters long."),
  confirmPassword: Yup.string()
    .required("Confirm Password is required")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
});

const ChangePassword = () => {
  const formikRef = useRef(null);
  const navigate = useNavigate();

  const [formLoading, setFormLoading] = useState(false);
  const [passwordShow, setPasswordShow] = useState(false);
  const [confirmPasswordShow, setConfirmPasswordShow] = useState(false);


  const handleSubmit = async (values) => {
    try {
      const { password } = values;
      setFormLoading(true);

      const fireBaseBackend = getFirebaseBackend();
      const response = await fireBaseBackend.updatePassword(password);
      setFormLoading(false);
      if (response?.id) {
        toast.success("Password updated successfully.");
        navigate("/");
      }
    } catch (err) {
      console.log(err);
      toast.error(err);
      setFormLoading(false);
    }
  };
  return (
    <>
      <Navbar login />
      <div className="signUp">
        <div className="signUp_form">
          <h3>Update Password</h3>
          <Formik
            initialValues={initialValues}
            validationSchema={ContactTeamSchema}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
            innerRef={formikRef}
          >
            {(formik) => {
              const { errors, touched } = formik;
              return (
                <Form>
                  <div className="signUp_passwordInput">
                    <FormikInput
                      type={passwordShow ? "text" : "password"}
                      label="Password"
                      placeholder="Enter Password"
                      id="password"
                      name="password"
                      touched={touched}
                      error={errors.password}
                    />
                    <img
                      onClick={() => setPasswordShow((prev) => !prev)}
                      src={!passwordShow ? EyeSlash : Eye}
                      alt="eye"
                    />
                  </div>
                  <div className="signUp_passwordInput">
                    <FormikInput
                      type={confirmPasswordShow ? "text" : "password"}
                      label="Confirm Password"
                      placeholder="Enter Confirm Password"
                      id="confirmPassword"
                      name="confirmPassword"
                      touched={touched}
                      error={errors.confirmPassword}
                    />
                    <img
                      onClick={() => setConfirmPasswordShow((prev) => !prev)}
                      src={!confirmPasswordShow ? EyeSlash : Eye}
                      alt="eye"
                    />
                  </div>

                  <button disabled={formLoading} type="submit">
                    {formLoading ? "Submitting..." : "Submit"}
                  </button>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
