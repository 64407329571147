import React, { useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import TextInput from "../../components/TextInput";
import DatePicker from "../../components/DatePicker";
import "./style.css";
import Navbar from "../../components/Navbar";

const Home = () => {
  const reportRef = useRef(null);
  const [dob, setDob] = useState(null);
  const [month, setMonth] = useState(null);
  const [year, setYear] = useState(null);
  const [name, setName] = useState("");
  const [drugs, setDrugs] = useState(["", "", "", "", "", ""]);
  const [hideWhilePrint,setHideWhilePrint]=useState(false);

  const reactToPrintContent = React.useCallback(() => {
    return reportRef.current;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportRef.current]);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: `${name}_MAR`,
    // removeAfterPrint: true,
    onBeforePrint:() => setHideWhilePrint(true),
    onAfterPrint:() => setHideWhilePrint(false)
  });

  const handleDrugClick = (drug) => {
    const tempDrugs = [...drugs];

    let findIndex = drugs.findIndex((d) => !d);
    if (findIndex === -1) findIndex = drugs.length - 1;
    tempDrugs[findIndex] = drug;
    setDrugs(tempDrugs);
  };

 

  return (
    <>
    {!hideWhilePrint &&
      <Navbar handleDrugClick={handleDrugClick} />
    }
      <div className="home">
        <div className="home_pageContainer">
          <h3>Medication Administration Record (MAR) Generator</h3>
          <page ref={reportRef}>
            <div className="home_pageWrapper">
              <div  className="home_page">
                <div className="home_pageTop">
                  <TextInput
                    label="Name:"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    id="patientName"
                    autoComplete="off"
                  />
                  <DatePicker
                    label="DOB:"
                    id="patientDOB"
                    showMonthDropdown
                    showYearDropdown
                    yearDropdownItemNumber={70}
                    scrollableYearDropdown
                    maxDate={new Date()}
                    dropdownMode="select"
                    selected={dob}
                    onChange={(date) => setDob(date)}
                    dateFormat="dd MMM, yyyy"
                    autoComplete="off`"
                  />
                  <div className="home_pageTopDate">
                    <DatePicker
                      label="Month:"
                      id="patientMonth"
                      selected={month}
                      onChange={(date) => setMonth(date)}
                      dateFormat="MMM"
                      showMonthYearPicker
                      customClassName="home_smallDatePicker"
                      autoComplete="off"
                    />
                    <DatePicker
                      label="Year:"
                      id="patientYear"
                      selected={year}
                      onChange={(date) => setYear(date)}
                      dateFormat="yyyy"
                      showYearPicker
                      customClassName="home_smallDatePicker"
                      autoComplete="off"
                    />
                  </div>
                </div>
                <div className="home_pageTop2">
                  <TextInput
                    autoComplete="off"
                    label="Allergies:"
                    containerClass="home_allergiesInput"
                    id="patientAllergies"
                  />
                  <TextInput
                    autoComplete="off"
                    label="Pharmacy Name:"
                    id="pharmacy"
                  />
                </div>
                <div className="home_table">
                  <div className="home_tableHeader">
                    <span>Medication</span>
                    <span>Time</span>
                    {[...Array(31).keys()].map((num) => (
                      <span>{num + 1}</span>
                    ))}
                  </div>
                  {drugs.map(
                    (
                      d,
                      i //[...Array(6).keys()]
                    ) => (
                      <div className="home_tableRow" key={i}>
                        <div className="home_tableRowMedication">
                          <span>
                            <textarea
                              placeholder="  "
                              value={d}
                              onChange={(e) => {
                                const tempDrugs = [...drugs];
                                tempDrugs[i] = e.target.value;
                                setDrugs(tempDrugs);
                              }}
                              rows="5"
                            ></textarea>

                            <div className="home_tableRowMedicationPlaceholder">
                              <span>Enter Drug Name ("e.g., Ibuprofen")</span>
                              <span>Dosage ("e.g., 20 MG")</span>
                              <span>
                                Enter Frequency ("e.g., Once daily", etc)
                              </span>
                              <span>Enter Direction ("e.g., With food")</span>
                              <span>Enter Route("e.g., Oral")</span>
                            </div>
                          </span>
                        </div>

                        <div className="home_tableRowTime">
                          {[...Array(5).keys()].map((num) => (
                            <span key={num}>
                              <input type="text" />
                            </span>
                          ))}
                        </div>
                        <div className="home_tableRowBoxes">
                          {[...Array(5).keys()].map((num) => (
                            <div key={num}>
                              {[...Array(31).keys()].map((num) => (
                                <span key={num}>{num + 1}</span>
                              ))}
                            </div>
                          ))}
                        </div>
                      </div>
                    )
                  )}
                  <div className="home_tableBottom">
                    <div className="home_tableNotes">
                      <span>NOTES:</span>
                      <span />
                      <span />
                      <span />
                    </div>
                    {[...Array(2).keys()].map((num) => (
                      <div key={num} className="home_tableSign">
                        <div>
                          <span>Signature</span>
                          <span />
                          <span />
                          <span />
                        </div>
                        <div>
                          <span>Initial</span>
                          <span />
                          <span />
                          <span />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </page>

          <button onClick={handlePrint} className="home_button">
            Download
          </button>
        </div>
      </div>
    </>
  );
};

export default Home;
