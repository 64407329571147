import { createClient } from "@supabase/supabase-js";

class FirebaseAuthBackend {
  constructor() {
    const url = "https://buicovuvxcigkufbeqhv.supabase.co/";
    const key =
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImJ1aWNvdnV2eGNpZ2t1ZmJlcWh2Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTYxNzEwNjcsImV4cCI6MjAxMTc0NzA2N30.UyfptrYsbk1S81AKWCGpKg89AsGM53XavxRZJycxFnU";

    this.supabase = createClient(url, key);

    this.supabase.auth.getSession().then((res) => {
      if (res?.data?.session) {
        sessionStorage.setItem("session", JSON.stringify(res?.data?.session));
      } else {
        sessionStorage.removeItem("session");
      }
    });
  }

  /**
   * Registers the user with given details
   */
  registerUser = (email, password, first_name, last_name) => {
    return new Promise((resolve, reject) => {
      this.supabase.auth
        .signUp({
          email,
          password,
          options: {
            data: {
              first_name,
              last_name,
            },
          },
        })
        .then(
          (res) => {
            if (res?.data?.user) {
              resolve(res?.data?.user);
            } else {
              reject(this._handleError(res?.error));
            }
          },
          (error) => {
            reject(this._handleError(error));
          }
        );
    });
  };

  /**
   * Login user with given details
   */
  loginUser = (email, password) => {
    return new Promise((resolve, reject) => {
      this.supabase.auth
        .signInWithPassword({
          email: email,
          password: password,
        })
        .then(
          (res) => {
            if (res?.data?.user) {
              const session = res?.data?.session;
              if (res?.data?.session?.user) {
                delete res?.data?.session?.["user"];
              }
              sessionStorage.setItem("session", JSON.stringify(session));
              resolve({ ...res?.data?.user });
              this.setLoggeedInUser(res?.data?.user);
            } else {
              reject(this._handleError(res?.error));
            }
          },
          (error) => {
            reject(this._handleError(error));
          }
        );
    });
  };

  /**
   * forget Password user with given details
   */
  forgetPassword = (email) => {
    return new Promise((resolve, reject) => {
      this.supabase.auth
        .resetPasswordForEmail(email, {
          redirectTo: encodeURI(
            `${process.env.REACT_APP_BASE_URL}/change-password`
          ),
        })
        .then(
          (res) => {
            if (!res?.error) {
              resolve(true);
            } else {
              reject(this._handleError(res?.error));
            }
          },
          (error) => {
            reject(this._handleError(error));
          }
        );
    });
  };

  /**
   * forget Password user with given details
   */
  updatePassword = (password) => {
    return new Promise((resolve, reject) => {
      this.supabase.auth
        .updateUser({
          password: password,
        })
        .then(
          (res) => {
            if (res?.data?.user) {
              resolve(res?.data?.user);
            } else {
              reject(this._handleError(res?.error));
            }
          },
          (error) => {
            reject(this._handleError(error));
          }
        );
    });
  };

  logout = () => {
    return new Promise((resolve, reject) => {
      this.supabase.auth.signOut().then((res) => {
        if (!res?.error) {
          resolve(true);
        } else {
          reject(this._handleError(res?.error));
        }
      });
    });
  };

  setLoggeedInUser = (user) => {
    sessionStorage.setItem("authUser", JSON.stringify(user));
  };

  /**
   * Returns the authenticated user
   */
  getAuthenticatedUser = () => {
    if (!sessionStorage.getItem("authUser")) return null;
    return JSON.parse(sessionStorage.getItem("authUser"));
  };

  /**
   * Handle the error
   * @param {*} error
   */
  _handleError(error) {
    // var errorCode = error.code;
    var errorMessage = error?.message;
    return errorMessage;
  }
}

let _fireBaseBackend = null;

/**
 * Initilize the backend
 * @param {*} config
 */
const initFirebaseBackend = (config) => {
  if (!_fireBaseBackend) {
    _fireBaseBackend = new FirebaseAuthBackend();
  }
  return _fireBaseBackend;
};

/**
 * Returns the firebase backend
 */
const getFirebaseBackend = () => {
  return _fireBaseBackend;
};

export { initFirebaseBackend, getFirebaseBackend };
