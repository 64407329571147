import React, { forwardRef } from "react";
import "./TextInput.scss";

const TextInput = forwardRef(
  ({ id, label, containerClass = "", type = "text", ...rest }, ref) => {
    return (
      <div className={`textInput ${containerClass} `}>
        {label && <label htmlFor={id}>{label}</label>}
        <input ref={ref} type={type} id={id} {...rest} />
      </div>
    );
  }
);

export default TextInput;
