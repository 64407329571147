import React, { useRef, useState } from "react";
import "./SignUp.scss";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import FormikInput from "../../components/FormikInput";
import Eye from "../../assets/eye.svg";
import EyeSlash from "../../assets/eye-slash.svg";
import { Link } from "react-router-dom";
import Navbar from "../../components/Navbar";
import { getFirebaseBackend } from "../../supabase";
import { toast } from "react-toastify";

const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
};

const ContactTeamSchema = Yup.object().shape({
  firstName: Yup.string().required("First Name is required."),
  lastName: Yup.string().required("Last Name is required."),
  email: Yup.string().email().required("Email is required"),
  password: Yup.string()
    .required("Password is required.")
    .min(8, "Password should be 8 characters long."),
});

const SignUp = () => {
  const formikRef = useRef(null);

  const [formLoading, setFormLoading] = useState(false);
  const [passwordShow, setPasswordShow] = useState(false);

  const handleSubmit = async (values) => {
    try {
      const { email, password, firstName, lastName } = values;
      setFormLoading(true);

      const fireBaseBackend = getFirebaseBackend();
      const response = await fireBaseBackend.registerUser(
        email,
        password,
        firstName,
        lastName
      );
      toast.success("Verification link is sent to your email.");
      setFormLoading(false);

      formikRef.current?.resetForm();
    } catch (err) {
      console.log(err);
      toast.error(err);
      setFormLoading(false);
    }
  };
  return (
    <>
      <Navbar login />
      <div className="signUp">
        <div className="signUp_form">
          <h3>Sign Up</h3>
          <Formik
            initialValues={initialValues}
            validationSchema={ContactTeamSchema}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
            innerRef={formikRef}
          >
            {(formik) => {
              const { errors, touched } = formik;
              return (
                <Form>
                  <FormikInput
                    label="First Name"
                    placeholder="Enter First Name"
                    id="firstName"
                    name="firstName"
                    touched={touched}
                    error={errors.firstName}
                  />
                  <FormikInput
                    label="Last Name"
                    placeholder="Enter Last Name"
                    id="lastName"
                    name="lastName"
                    touched={touched}
                    error={errors.lastName}
                  />
                  <FormikInput
                    label="Email"
                    placeholder="Enter Email"
                    id="email"
                    name="email"
                    touched={touched}
                    error={errors.email}
                  />
                  <div className="signUp_passwordInput">
                    <FormikInput
                      type={passwordShow ? "text" : "password"}
                      label="Password"
                      placeholder="Enter Password"
                      id="password"
                      name="password"
                      touched={touched}
                      error={errors.password}
                    />
                    <img
                      onClick={() => setPasswordShow((prev) => !prev)}
                      src={!passwordShow ? EyeSlash : Eye}
                      alt="eye"
                    />
                  </div>

                  <button disabled={formLoading} type="submit">
                    {formLoading ? "Signing... up" : "Sign Up"}
                  </button>
                </Form>
              );
            }}
          </Formik>
          <div>
            Already have an account. <Link to="/login">Log in.</Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUp;
