import React, { useRef, useState } from "react";
import "./Login.scss";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import FormikInput from "../../components/FormikInput";
import Eye from "../../assets/eye.svg";
import EyeSlash from "../../assets/eye-slash.svg";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "../../components/Navbar";
import { getFirebaseBackend } from "../../supabase";
import { toast } from "react-toastify";

const initialValues = {
  email: "",
  password: "",
};

const Login = () => {
  const formikRef = useRef(null);
  const navigate = useNavigate();

  const [formLoading, setFormLoading] = useState(false);
  const [passwordShow, setPasswordShow] = useState(false);
  const [forgetPasswordCheck, setForgetPasswordCheck] = useState(false);

  const handleSubmit = async (values) => {
    if (formLoading) return;
    try {
      const { email, password } = values;
      setFormLoading(true);
      let fireBaseBackend = getFirebaseBackend();
      if (forgetPasswordCheck) {
        let response;
        response = fireBaseBackend.forgetPassword(email);
        const data = await response;
        if (data)
          toast.success(`A password reset link has been sent to ${email}`);
      } else {
        const response = await fireBaseBackend.loginUser(email, password);
        navigate("/");
      }
      formikRef.current?.resetForm();
      setFormLoading(false);
    } catch (err) {
      console.log(err);
      toast.error(err);
      setFormLoading(false);
    }
  };

  const ContactTeamSchema = Yup.object().shape({
    email: Yup.string().email().required("Email is required"),
    password: forgetPasswordCheck
      ? Yup.string()
      : Yup.string().required("Password is required."),
  });

  return (
    <>
      <Navbar login />
      <div className="login">
        <div className="login_form">
          <h3>{forgetPasswordCheck ? "Forget Password" : "Log In"} </h3>
          <Formik
            initialValues={initialValues}
            validationSchema={ContactTeamSchema}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
            innerRef={formikRef}
          >
            {(formik) => {
              const { errors, touched } = formik;
              return (
                <Form>
                  <FormikInput
                    label="Email"
                    placeholder="Enter Email"
                    id="email"
                    name="email"
                    touched={touched}
                    error={errors.email}
                  />
                  {!forgetPasswordCheck && (
                    <div className="login_passwordInput">
                      <FormikInput
                        type={passwordShow ? "text" : "password"}
                        label="Password"
                        placeholder="Enter Password"
                        id="password"
                        name="password"
                        touched={touched}
                        error={errors.password}
                      />
                      <img
                        onClick={() => setPasswordShow((prev) => !prev)}
                        src={!passwordShow ? EyeSlash : Eye}
                        alt="eye"
                      />
                    </div>
                  )}

                  <div
                    className="login_forgetPassword"
                    onClick={() => setForgetPasswordCheck(!forgetPasswordCheck)}
                  >
                    {forgetPasswordCheck ? "Login?" : "Forget Password?"}
                  </div>

                  <button disabled={formLoading} type="submit">
                    {formLoading ? "Submitting..." : "Submit"}
                  </button>
                </Form>
              );
            }}
          </Formik>
          <div>
            Don't have any account. <Link to="/sign-up">Sign Up</Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
